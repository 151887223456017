import React, {
    forwardRef,
    memo,
    PropsWithChildren,
    useCallback,
    useEffect,
    useId,
    useRef,
} from 'react';
import {
    StyledBackdrop,
    StyledCloseButton,
    StyledHeader,
    StyledOverlay,
    StyledOverlayWrapper,
} from './styled';
import Close from '$icons/close.svg';
import { useTranslation } from '~/shared/utils/translation';
import { useScrollLock } from '~/shared/hooks';
import { Text } from '~/shared/components';
import { useKey } from 'react-use';
import { animationVariants } from './animations';
import { useTheme } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import { useFocusTrap } from '~/shared/hooks/useFocusTrap';
import { weakKey } from '~/shared/utils/jsx';
import { useThemeShade } from '~/theme';
import { Router } from 'next/router';

export type Variants = 'full' | 'minimal' | 'slide' | 'page';

export type OverlayProps = {
    onDismiss: () => void; // Remember to wrap the argument in useCallback, since it controls the 'open' state
    onLoad?: () => void;
    variant?: Variants;
    backdrop?: boolean;
    scrollLock?: boolean;
    open: boolean;
    headline?: string | JSX.Element;
};

export const Overlay = memo(
    forwardRef<HTMLDivElement, PropsWithChildren<OverlayProps>>(
        (
            {
                onDismiss,
                onLoad,
                open = false,
                children,
                variant = 'minimal',
                backdrop = true,
                scrollLock = true,
                headline,
            }: PropsWithChildren<OverlayProps>,
            ref
        ) => {
            const id = useId();
            const overlayRef = useRef<HTMLDivElement>(null);
            const { translate } = useTranslation();
            const { lock, unlock } = useScrollLock();
            const theme = useTheme();
            const variants = animationVariants(variant, theme);
            const ariaDialogLabelledBy = `dialog${weakKey({ headline }).replace('-', '')}Title`; // Aria must not contain hyphens
            const ariaDialogDescribedBy = `dialog${weakKey({ headline }).replace('-', '')}Desc`; // Aria must not contain hyphens
            const isFullButton = variant === 'full' && !headline;
            const { headlineShade, buttonShade } = useThemeShade({
                backgroundColor: theme.traits.background.default,
            });

            useFocusTrap(overlayRef.current);

            const dismiss = useCallback(() => onDismiss(), [onDismiss]);
            const load = useCallback(() => onLoad?.(), [onLoad]);

            useKey('Escape', dismiss, {
                target: overlayRef.current,
            });

            useEffect(() => {
                if (open) {
                    load();
                    if (scrollLock) {
                        lock(id);
                    }
                } else {
                    dismiss();
                    if (scrollLock) {
                        unlock(id);
                    }
                }
            }, [open, dismiss, load, lock, unlock, id, scrollLock]);

            const onRouteChangeStart = useCallback(() => {
                if (open) {
                    dismiss();
                }
            }, [open, dismiss]);

            useEffect(() => {
                Router.events.on('routeChangeStart', onRouteChangeStart);
                return () => {
                    Router.events.off('routeChangeStart', onRouteChangeStart);
                };
            }, [onRouteChangeStart]);

            return (
                <AnimatePresence>
                    {open && (
                        <StyledOverlayWrapper ref={overlayRef} variant={variant}>
                            {backdrop && (
                                <StyledBackdrop
                                    key="backdrop"
                                    onClick={dismiss}
                                    role="button"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    aria-label={translate('general.close')}
                                />
                            )}
                            <StyledOverlay
                                key="overlay"
                                variants={variants}
                                variant={variant}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                role="dialog"
                                aria-modal="true"
                                aria-labelledby={ariaDialogLabelledBy}
                                aria-describedby={ariaDialogDescribedBy}
                                ref={ref}
                                id={ariaDialogDescribedBy}
                            >
                                <StyledHeader solid={!!headline} variant={variant}>
                                    {headline && (
                                        <Text
                                            id={ariaDialogLabelledBy}
                                            as="h2"
                                            style={{ fontWeight: 'bold' }}
                                            shade={headlineShade}
                                        >
                                            {headline}
                                        </Text>
                                    )}
                                    <StyledCloseButton
                                        type="button"
                                        onClick={dismiss}
                                        variant={isFullButton ? 'secondary' : 'tertiary'}
                                        shape="icon"
                                        shade={isFullButton ? 'dark' : buttonShade}
                                        showHoverIndicator={isFullButton}
                                        size={isFullButton ? 'lg' : 'md'}
                                        isFullButton={isFullButton}
                                    >
                                        <Close title={translate('general.close')} />
                                    </StyledCloseButton>
                                </StyledHeader>
                                {children}
                            </StyledOverlay>
                        </StyledOverlayWrapper>
                    )}
                </AnimatePresence>
            );
        }
    )
);
