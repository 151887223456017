import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { switchProp, ifProp } from 'styled-tools';
import { Variants } from '~/shared/components';
import { cover } from '~/shared/utils/styled';
import { breakpoints } from '~/theme';
import { Button } from '../Button';

export const dialogHeaderHeight = 60;
const dialogHeightClampSettings = {
    min: '300px',
    base: '75vh',
    max: '85vh',
};
const dialogHeight = (() => {
    const c = dialogHeightClampSettings;
    return `clamp(${c.min}, ${c.base}, ${c.max})`;
})();
// TODO: If refactoring overlay, we could scroll on content instead of the whole overlay. Then this wouldn't be needed.
export const dialogHeightMinusHeader = (() => {
    const h = dialogHeaderHeight;
    const c = dialogHeightClampSettings;
    return `clamp(calc(${c.min} - ${h}px), calc(${c.base} - ${h}px), calc(${c.max} - ${h}px))`;
})();

export const StyledOverlayWrapper = styled.div<{ variant: Variants }>(
    ({ theme }) => ({
        position: 'relative',
        zIndex: theme.zIndices.max,
        overflowX: 'hidden',
    }),
    switchProp('variant', {
        slide: {
            [breakpoints.sm]: {
                paddingLeft: '5px', // FIX quirk with spring animations going outside overflow
            },
        },
    })
);

export const StyledOverlay = styled(m.div)<{ variant: Variants }>(
    ({ theme }) => ({
        overflowY: 'auto',
        maxHeight: ['100vh', '100dvh'],
        background: theme.colors.light,
        outline: theme.borders.outline, // Outline works better than border with the animation
    }),
    switchProp('variant', {
        full: {
            ...cover,
            position: 'fixed',
            height: 'fill-available',
            outline: 'none',
        },
        minimal: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            maxHeight: '80vh',
            width: 'fit-content',
            [breakpoints.md]: {
                width: '100%',
                maxWidth: 'clamp(600px, 40vw, 900px)',
                maxHeight: dialogHeight,
            },
        },
        slide: {
            outline: 'none',
            height: ['100vh', '100dvh'],
            flexDirection: 'column',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            [breakpoints.md]: {
                marginLeft: 'auto',
                maxWidth: '530px',
            },
        },
        page: ({ theme }) => ({
            ...cover,
            position: 'fixed',
            margin: 'auto',
            [breakpoints.md]: {
                maxWidth: theme.sizes.content,
                maxHeight: '90vh',
            },
        }),
    })
);

export const StyledCloseButton = styled(Button)<{ isFullButton: boolean }>(
    {
        marginLeft: 'auto',
    },
    ifProp('isFullButton', ({ theme }) => ({
        marginTop: theme.spaces[4],
        marginRight: `calc(${theme.spaces[4]} - ${theme.spaces[1]})`,
    }))
);

export const StyledBackdrop = styled(m.div)(({ theme }) => ({
    position: 'fixed',
    inset: '0 0 0 0',
    background: theme.colors.transparentDark,
    backdropFilter: 'grayscale(0.35)',
    cursor: 'pointer',
    pointerEvents: 'all',
    zIndex: -1,
}));

export const StyledHeader = styled.header<{ solid: boolean; variant: Variants }>(
    ({ theme }) => ({
        position: 'sticky',
        zIndex: theme.zIndices[1],
        top: '0',
        height: dialogHeaderHeight,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spaces[3],
        paddingRight: theme.spaces[1],
    }),
    ifProp('solid', ({ theme }) => ({
        borderBottom: theme.borders.border,
        background: theme.traits.background.default,
    })),
    switchProp('variant', {
        full: ({ solid }) => ({
            // Absolute positioning does not work inside scroll, so we simply let sticky do its thing.
            // By adding minus margin, we make this element move out of screen (and therefore removing whitespace)
            // while sticky makes sure it is still visible.
            marginTop: solid ? 0 : `-${dialogHeaderHeight}px`,
        }),
    })
);
