import { CSSObject } from '@emotion/react';
import create, { SetState, GetState } from 'zustand';
import { isDevice } from './utils';

type UseScrollLockState = {
    // The current open state
    isLocked: boolean;

    // Locked scroll position
    scrollY: number;

    // List of ID's that has enabled scrollLock. Only unlock when list is exhausted.
    lockedIds: string[];

    // Specify the open state
    setIsLocked: (isLocked: boolean) => void;
    lock: (id: string) => void;
    unlock: (id: string) => void;
};

const deviceLockStyles: CSSObject = {
    overflow: 'hidden',
    touchAction: 'none',
};

const desktopLockStyles: CSSObject = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'scroll',
};

const scrollUnlockStyles = {
    overflow: '',
    touchAction: '',
    position: '',
    top: '',
    bottom: '',
    left: '',
    right: '',
};

export const useScrollLock = create<UseScrollLockState>(
    (set: SetState<UseScrollLockState>, get: GetState<UseScrollLockState>): UseScrollLockState => ({
        isLocked: false,
        setIsLocked: (isLocked: boolean) => set({ isLocked }),
        scrollY: 0,
        lockedIds: [],
        lock: (id: string) => {
            const state: UseScrollLockState = get();

            if (state.lockedIds.includes(id)) {
                return;
            }

            const lockedIds = [...state.lockedIds, id];
            const scrollY = window.scrollY;
            const doc = document.documentElement;

            set({ isLocked: true, scrollY, lockedIds });

            if (isDevice()) {
                Object.assign(doc.style, deviceLockStyles);
                return;
            }

            Object.assign(doc.style, desktopLockStyles);
            doc.style.top = `-${scrollY}px`;
        },

        unlock: (id: string) => {
            const state: UseScrollLockState = get();

            if (state.lockedIds.length === 0) {
                return;
            }
            const lockedIds = state.lockedIds.filter((lockedId) => lockedId !== id);

            if (lockedIds.length > 0) {
                set({ lockedIds });
                return;
            }

            const doc = document.documentElement;
            set({ isLocked: false, scrollY: 0, lockedIds });

            Object.assign(doc.style, scrollUnlockStyles);

            if (isDevice()) {
                return;
            }

            // Make sure scrollY is restored instantly
            const currentScrollBehavior = doc.style.scrollBehavior;
            doc.style.scrollBehavior = '';
            window.scrollTo(0, state.scrollY);
            doc.style.scrollBehavior = currentScrollBehavior;
        },
    })
);
