import { useCallback, useEffect, useMemo } from 'react';

/**
 * Use this the keep tab shift focus within a element
 * Usefull for e.g. dialogs, where the WCAG spec, says you should "trap" the user and make them close the dialog.
 * @param element HTMLDivElement
 */
export const useFocusTrap = (element: HTMLDivElement | null) => {
    const { firstFocusableElement, lastFocusableElement } = useMemo(() => {
        const focusableElements =
            element?.querySelectorAll(
                'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
            ) || [];

        const firstFocusableElement = focusableElements[0] as HTMLButtonElement;
        const lastFocusableElement = focusableElements[
            focusableElements.length - 1
        ] as HTMLButtonElement;
        return { firstFocusableElement, lastFocusableElement };
    }, [element]);

    const keyboardListener = useCallback(
        (event: KeyboardEvent) => {
            // Reenable focus styling at first keypress regardless of key
            firstFocusableElement.style.outline = '';

            const isTabPressed = event.key === 'Tab';

            if (!isTabPressed) {
                return;
            }

            if (event.shiftKey) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    event.preventDefault();
                }
            } else {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    event.preventDefault();
                }
            }
        },
        [firstFocusableElement, lastFocusableElement]
    );

    const setFocus = useCallback(() => {
        if (firstFocusableElement) {
            // Make sure we do not have any visible focus on the initial focus trapping
            firstFocusableElement.style.outline = 'none';
            firstFocusableElement.focus();
        }
    }, [firstFocusableElement]);

    useEffect(() => {
        if (!element) {
            return;
        }

        setFocus();

        element.addEventListener('keydown', keyboardListener);

        return () => {
            element.removeEventListener('keydown', keyboardListener);
        };
    }, [element, keyboardListener, setFocus]);
};
